// eslint-disable-next-line import/no-extraneous-dependencies
import Swiper from 'swiper/bundle';

// 周辺のおすすめスポットswiper
const courseDaySpotRecommendationsSwiperElms = document.querySelectorAll(
  "[data-role='course-day-spot-recommendations-swiper']"
);
if (courseDaySpotRecommendationsSwiperElms.length) {
  courseDaySpotRecommendationsSwiperElms.forEach((elm) => {
    let courseDaySpotRecommendationsSwiper = new Swiper(elm.querySelector('.swiper'), {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 16,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      navigation: {
        nextEl: elm.querySelector('[data-role="course-day-spot-recommendations-swiper-nav-next"]'),
        prevEl: elm.querySelector('[data-role="course-day-spot-recommendations-swiper-nav-prev"]'),
      },
    });
  });
}
